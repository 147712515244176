<script setup lang="ts">
import { computed } from 'vue'

import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'
import MainPolicySwitcher from '@kidzonet/dashboard-main-policy-switcher'

import ScheduleExpansionPanel from '@kidzonet/dashboard-schedule'

const store = useDasboardPolicyStore()

const settingsStore = useSettingsStore()

const activate = async (name: string, id?: number) => {
    if (store.activePolicies.some(item => item.id === id)) {
        return
    }
    const promiseList = []
    promiseList.push(store.activatePolicy(id))
    if (name === 'kids_home_alone') {
        promiseList.push(settingsStore.setSettings({ safeSearch: true, safeYoutube: true }))
    } else if (name === 'holiday_time') {
        promiseList.push(settingsStore.setSafeSearch(true))
    }
    await Promise.all(promiseList)
}

const isPoliciesLoaded = computed(() => {
    return store.singlePolicies && store.singlePolicies.length > 0
})

const changePolicy = async () => {
    await activate(store.inactiveSinglePolicy.name, store.inactiveSinglePolicy.id)
}
</script>

<template>
    <div
        v-if="isPoliciesLoaded && store.onPolicy.id"
        class="policy-options-content"
    >
        <MainPolicySwitcher
            @change-policy="changePolicy"
        >
            <template #bottom>
                <ScheduleExpansionPanel
                    @click.stop
                    v-if="store.onPolicy.isActive"
                    class="policy-options-content-schedule"
                    name="holiday_time"
                    :id="store.onPolicy.id"
                />
            </template>
        </MainPolicySwitcher>
    </div>
</template>

<style>
    .policy-options-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;

        @media(max-width: 900px) {
            grid-template-columns: 1fr;
        }
    }
    .policy-options-content-schedule {
        margin-block-start: 1em;
    }
</style>
