<script setup lang="ts">
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import useSimpleUIGUard from '@kidzonet/use-simple-ui-guard'
import PolicyOptions from './PolicyOptions/PolicyOptions.vue'
import ProtectionSettings from './ProtectionSettings/ProtectionSettings.vue'

const store = useDasboardPolicyStore()
store.fetchUserPolicies()
useLoadLocaleMessages('policy')
useSimpleUIGUard()
</script>

<template>
    <div class="kidonet-policy-page">
        <PolicyOptions
            class="kidzonet-dashboard-widget kidonet-policy-page__policy-options"
        />
        <ProtectionSettings />
    </div>
</template>

<style>
    .kidonet-policy-page {
        text-transform: capitalize;

        &__policy-options {
            margin-block-end: 1em;
        }
        &__widget-header {
            margin-block-end: 1em;
            font-size: 14px;
            color: rgb(var(--v-theme-subHeader));
        }

        .kidzonet-policy-icon {
            @media(max-width: 600px) {
                display: none;
            }
        }
    }
</style>
