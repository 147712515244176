declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

interface CreateHostParams {
    host: string
    handleError: (msg: string) => void
}

export const getHostsList = async () => {
    // const { data, error }: GetDynDNSHostsResponseInterface = await globalThis.DashboardApiClient.GET(
    //     '/v1/public/dashboard/interfaces',
    // )

    const { data, error } = {
        data: {
            items: [{ id: 1, host: 'example.com' }],
        },
        error: undefined,
    }

    if (error) {
        throw error
    }
    const { items = [] } = data
    return items.map(item => ({ id: item.id, host: item.host }))
}

export const createHost = async ({ host, handleError }: CreateHostParams) => {
    // const { data, error }: CreateDynDNSHostResponseInterface = await globalThis.DashboardApiClient.POST(
    //     '/v1/public/dashboard/interfaces', {
    //         body: { interface: address },
    //     },
    // )
    const { data, error } = {
        data: { id: 1, host },
        error: undefined,
    }

    if (data) {
        return data.id
    }
    // @ts-expect-error remove me when api integration will be finished
    if (error && 'status' in error && error.status && [409, 422].includes(error.status)) {
        // @ts-expect-error remove me when api integration will be finished
        handleError(error.detail)
        return false
    }
    throw error
}

export const deleteHost = async (id: number) => {
    // const { data, error }: DeleteDynDNSHostResponseInterface = await globalThis.DashboardApiClient.DELETE(
    //     `/v1/public/dashboard/interfaces/${id}`,
    // )
    const { data, error } = {
        data: id,
        error: undefined,
    }

    if (data) {
        return true
    }
    // @ts-expect-error remove me when api integration will be finished
    if (error && 'status' in error && error.status === 404) {
        return true
    }
    throw error
}
