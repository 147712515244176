<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import domainValidation from '@kidzonet/vue3-domain-validation'
import { useDynDNSStore } from '@kidzonet/dashboard-dyn-dns-store'

const { t } = useI18n()
const host = ref('')

const store = useDynDNSStore()

const updateHost = (value: string) => {
    host.value = value
}

const rules = computed(() => ({
    host: {
        ...domainValidation,
    },
}))

// eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
// @ts-ignore
const $v = useVuelidate(rules, { host })
const buttonIsDisabled = computed(() => $v.value.$invalid)

const addHost = async () => {
    $v.value.host.$touch()
    if (buttonIsDisabled.value) {
        return
    }
    host.value = ''
    await nextTick()
    $v.value.host.$reset()
}

const clear = () => {
    host.value = ''
    $v.value.host.$reset()
}

const blur = () => $v.value.host.$touch()

const errors = computed(() => {
    const errors: string[] = []
    if (!$v.value.host.$dirty || host) {
        $v.value.host.$reset()
        return errors
    }
    if ($v.value.host.$invalid) {
        errors.push(t('common.domains.errors.invalidDomain'))
    }
    return errors
})
</script>

<template>
    <div class="hosts-add-form">
        <slot
            name="input"
            :enter="addHost"
            :clear="clear"
            :blur="blur"
            :error-messages="errors"
            :input-value="host"
            :update-input-value="updateHost"
        >
            <v-text-field
                :label="t('dashboard_nets.dyn_dns.add')"
                v-model="host"
                autocapitalize="none"
                variant="outlined"
                color="primary"
                clearable
                :error-messages="errors"
                @keyup.enter="addHost"
                @click:clear="clear"
                @blur="blur"
            />
        </slot>
        <slot
            name="button"
            @click="addHost"
            :disabled="buttonIsDisabled"
        >
            <v-btn
                class="hosts-add-form__button"
                @click="addHost"
                :disabled="buttonIsDisabled"
                color="primary"
                rounded="lg"
                size="x-large"
            >
                {{ t('common.buttons.add') }}
            </v-btn>
        </slot>
    </div>
</template>

<style>
    .hosts-add-form {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;

        @media(max-width: 600px) {
            flex-direction: column;
        }
    }
</style>
