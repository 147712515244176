<template>
    <div class="circle-icon">
        <div class="circle-icon__inner" />
    </div>
</template>

<style>
    .circle-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        min-inline-size: 48px;
        min-block-size: 48px;

        &__inner {
            inline-size: 2em;
            block-size: 2em;
            border-radius: 50%;
        }
    }
</style>
