<script setup lang="ts">
import { ref } from 'vue'
import { useDynDNSStore } from '@kidzonet/dashboard-dyn-dns-store'

import AddForm from './Form.vue'
import Host from './Host.vue'

const showForm = ref(false)

const store = useDynDNSStore()
store.fetchHostsList()
</script>

<template>
    <div v-if="showForm">
        <v-progress-linear
            v-if="store.loading"
            indeterminate
            color="primary"
            size="100"
            width="10"
        />
        <AddForm v-else-if="store.canUserAddHosts" />
        <Host v-else-if="store.hosts.length > 0" />
    </div>
</template>
