export default [
    {
        name: 'holiday_time',
        single: true,
    }, {
        name: 'allow_all',
        single: true,
    }, {
        name: 'youtube',
        single: false,
    }, {
        name: 'social_media',
        single: false,
    }, {
        name: 'online_games',
        single: false,
    }, {
        name: 'online_music_and_video',
        single: false,
    },
]
