<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTimeoutFn } from '@vueuse/core'
import { vOnClickOutside } from '@vueuse/components'

import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'

import ProtectionOnIcon from '@kidzonet/dashboard-policy-icons/src/ProtectionOn.vue'

import PolicyItem from '@kidzonet/dashboard-policy-widget'
import { getPolicyName } from '@kidzonet/use-policy'

import Switcher from '@kidzonet/dashboard-policy-swithcer'

const emit = defineEmits(['changePolicy'])

const { t } = useI18n()

const store = useDasboardPolicyStore()

const tooltip = ref('')
const showTooltip = ref(false)

const isPoliciesLoaded = computed(() => {
    return store.singlePolicies && store.singlePolicies.length > 0
})

watch(isPoliciesLoaded, () => {
    tooltip.value = t(`policy.${store.activeSinglePolicy.name}.long_description`)
})

const { start, stop } = useTimeoutFn(() => {
    showTooltip.value = false
}, 3000)

const switchPolicy = async () => {
    stop()
    tooltip.value = t(`policy.${store.inactiveSinglePolicy.name}.long_description`)
    showTooltip.value = true
    await emit('changePolicy')
    start()
}

const clickOutside = () => {
    showTooltip.value = false
    stop()
}
</script>

<template>
    <PolicyItem
        v-on-click-outside="clickOutside"
        name="holiday_time"
        :id="store.activeSinglePolicy.id"
        :header="getPolicyName('holiday_time')"
        subheader=""
        :is-active="store.onPolicy.isActive"
        @click="switchPolicy"
        v-tooltip="{
            text: tooltip,
            location: 'bottom',
            maxWidth: '400',
            openOnHover: false,
            persistent: showTooltip,
            closeDelay: 300,
            modelValue: showTooltip,
        }"
    >
        <template #icon>
            <ProtectionOnIcon />
        </template>
        <Switcher
            :model-value="store.onPolicy.isActive"
        />
        <template #bottom>
            <slot name="bottom" />
        </template>
    </PolicyItem>
</template>

