<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useDynDNSStore } from '@kidzonet/dashboard-dyn-dns-store'

const { t } = useI18n()
const store = useDynDNSStore()
</script>

<template>
    <div class="hosts-details">
        <h3 class="hosts-details__header">
            {{ t('dashboard_nets.dyn_dns.header') }}
        </h3>
        <v-list
            lines="one"
            class="hosts-details__list"
        >
            <v-list-item
                :title="store.hosts[0].host"
                max-height="16px"
            >
                <template #append>
                    <v-btn
                        size="x-small"
                        icon="delete"
                        variant="text"
                        color="grey"
                        @click="() => store.removeHost(store.hosts[0])"
                    />
                </template>
            </v-list-item>
        </v-list>
    </div>
</template>

<style>
    .hosts-details {
        &__list {
            padding: 0;
        }
    }
</style>
