import { defineStore } from '@kidzonet/pinia'
import { notify } from '@kyvg/vue3-notification'

import { createHost, deleteHost, getHostsList } from '@kidzonet/ts-api-dashboard-dyn-dns'

interface hostItem {
    id: number
    host: string
}

export const useDynDNSStore = defineStore('dyn-dns', {
    state: () => {
        const hosts: hostItem[] = []
        const userId: number | null = null
        return {
            hosts,
            loading: false,
            userId,
            myIP: '',
        }
    },
    getters: {
        canUserAddHosts: state => state.hosts.length > 1,
    },
    actions: {
        async fetchHostsList () {
            this.loading = true
            const hosts = await getHostsList() || []
            this.hosts = hosts
            this.loading = false
        },
        async addHost (host: string) {
            this.loading = true
            if (this.canUserAddHosts) {
                const id = await createHost(
                    {
                        host,
                        handleError: (message: string) => {
                            notify({
                                type: 'error',
                                title: message,
                            })
                        },
                    },
                )
                if (id) {
                    await this.fetchHostsList()
                }
            }
            this.loading = false
        },
        async removeHost (host: hostItem) {
            this.loading = true
            const result = await deleteHost(host.id)
            if (result) {
                await this.fetchHostsList()
            }
            this.loading = false
        },
    },
})
