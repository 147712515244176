<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import Content from './Content.vue'

const { t } = useI18n()
</script>

<template>
    <div class="protection-settings-panel">
        <div class="kidzonet-dashboard-widget protection-settings-panel__inner">
            <h3 class="kidonet-policy-page__widget-header">
                {{ t('dashboard_v2.policy_page.protection_settings.header') }}
            </h3>
            <Content />
        </div>
    </div>
</template>

<style>
    .protection-settings-panel {
        display: grid;
        grid-template-areas: "overlaydemo";

        &__inner {
            grid-area: overlaydemo;
        }
    }
</style>
