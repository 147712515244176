<script setup lang="ts">
import { StyleValue } from 'vue'

import PolicyIcon from './Icon.vue'
import Chevron from './Chevron.vue'

interface PolicyWidgetProps {
    header: string
    icon?: string
    iconColor?: StyleValue
}

const props = defineProps<PolicyWidgetProps>()
</script>

<template>
    <div
        class="kidzonet-dashboard-widget overview-policy-widget"
    >
        <div class="overview-policy-widget__inner">
            <slot name="icon">
                <PolicyIcon
                    v-if="props.icon && props.iconColor"
                    :icon="props.icon"
                    :style="props.iconColor"
                />
            </slot>
            <div class="overview-policy-widget__content">
                <h2
                    class="overview-policy-widget__header font-semibold"
                >
                    {{ props.header }}
                </h2>
            </div>
            <slot name="action">
                <Chevron />
            </slot>
        </div>
        <slot />
    </div>
</template>

<style>
    .overview-policy-widget {
        block-size: 100%;

        &__inner {
            display: flex;
            gap: 1em;
            align-items: center;
            text-transform: capitalize;
        }

        &__content {
            flex-grow: 1;
        }

        .overview-policy-widget__header {
            font-size: 15px;
            line-height: 24px;
        }
    }
</style>
