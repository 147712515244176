<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'

import PolicyItem from '@kidzonet/dashboard-policy-widget'
import BlockSocialMediaIcon from '@kidzonet/dashboard-policy-icons/src/BlockSocialMedia.vue'
import MainPolicySwitcher from '@kidzonet/dashboard-main-policy-switcher'

import Switcher from '@kidzonet/dashboard-policy-swithcer'

const { t } = useI18n()

useLoadLocaleMessages('policy')

const store = useDasboardPolicyStore()
store.fetchUserPolicies()

const settingsStore = useSettingsStore()

const protectionOnPolicy = ref({ id: 0, name: '', isActive: false })
const protectionOffPolicy = ref({ id: 0, name: '', isActive: false })
const blockSocialMediaPolicy = ref({ id: 0, name: '', isActive: false })

const activateProtectionOn = async () => {
    if (protectionOnPolicy.value.isActive) {
        return
    }
    if (protectionOnPolicy.value.id === undefined) {
        return
    }
    await Promise.all([
        store.activateOneClickPolicy(protectionOnPolicy.value.id),
        settingsStore.setSafeSearch(true),
    ])
}

const activateProtectionOff = async () => {
    if (protectionOffPolicy.value.isActive) {
        return
    }
    if (protectionOffPolicy.value.id === undefined) {
        return
    }
    await Promise.all([
        store.activateOneClickPolicy(protectionOffPolicy.value.id),
        settingsStore.setSettings({ safeSearch: false, safeYoutube: false }),
    ])
}

store.$subscribe(() => {
    if (store.activePolicy === undefined) {
        return
    }

    // eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
    // @ts-ignore
    protectionOnPolicy.value = store.policyByName('holiday_time')

    // eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
    // @ts-ignore
    protectionOffPolicy.value = store.policyByName('allow_all')

    // eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
    // @ts-ignore
    blockSocialMediaPolicy.value = store.policyByName('social_media')
})

const changePolicy = async () => {
    if (store.inactiveSinglePolicy.name === 'holiday_time') {
        await activateProtectionOn()
    } else {
        await activateProtectionOff()
    }
}
</script>

<template>
    <div class="simple-interface-page">
        <div v-if="store.policyList.length > 0">
            <div
                class="kidzonet-dashboard-widget simple-interface-content"
            >
                <MainPolicySwitcher
                    @change-policy="changePolicy"
                />
                <PolicyItem
                    :name="blockSocialMediaPolicy.name"
                    :id="blockSocialMediaPolicy.id"
                    :is-active="blockSocialMediaPolicy.isActive"
                    @click="() => store.activatePolicy(blockSocialMediaPolicy.id)"
                    class="simple-interface-content__item"
                >
                    <template #icon>
                        <BlockSocialMediaIcon />
                    </template>
                    <Switcher
                        :model-value="blockSocialMediaPolicy.isActive"
                    />
                </PolicyItem>
            </div>
        </div>
    </div>
</template>

<style>
    .simple-interface-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        block-size: 60vh;
    }
    .simple-interface-content {
        display: flex;
        flex-direction: column;
        gap: 1em;

        min-inline-size: 500px;
        max-inline-size: 500px;


        @media(max-width: 800px) {
            min-inline-size: inherit;
        }
    }
</style>
