import { defineStore } from '@kidzonet/pinia'

import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import { createSchedule, deleteSchedule, getSchedule, getSchedulesList } from '@kidzonet/ts-api-dashboard-schedule'
import { getPolicyName } from '@kidzonet/use-policy'

type shortDayName = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

interface Interval {
    id: number
    weekdays: shortDayName[]
    startTime: string
    stopTime: string
    policy?: number
}

interface CreateIntervalInterface extends Interval {
    name: string
}

const overlapingError = 'Overlap schedules with ids=['

export const useScheduleStore = defineStore('schedule', {
    state: () => {
        const allow_all: Interval[] = []
        const holiday_time: Interval[] = []
        const overlapErrorIntervalList: Interval[] = []
        return {
            listLoading: false,
            addLoading: false,
            deleteLoading: false,
            addValidationError: false,
            allow_all,
            holiday_time,
            overlapErrorIntervalList,
        }
    },
    getters: {
        loading: state => state.listLoading || state.addLoading || state.deleteLoading,
        policyNamesDict () {
            const policyStore = useDasboardPolicyStore()
            const result = {}
            policyStore.policyList.forEach((item) => {
                // @ts-expect-error custom window property
                result[item.id] = getPolicyName(item.name)
            })
            return result
        },
        showError: state => state.overlapErrorIntervalList.length > 0 || state.addValidationError,
    },
    actions: {
        async fetchSchedule (
            { id, name }: { id: number, name: string },
        ) {
            if (this.listLoading) {
                return
            }
            this.listLoading = true
            try {
                const result = await getSchedulesList(id)
                if (name === 'allow_all') {
                    this.allow_all = result
                } else if (name === 'holiday_time') {
                    this.holiday_time = result
                }
            } finally {
                this.listLoading = false
            }
        },
        async addInterval (
            { id, name, startTime, stopTime, weekdays }: CreateIntervalInterface,
        ) {
            if (this.addLoading) {
                return
            }
            this.addLoading = true
            this.overlapErrorIntervalList = []
            try {
                const result = await createSchedule({
                    policies: [id],
                    weekdays,
                    start_time: startTime,
                    stop_time: stopTime,
                })
                if ('status' in result && result.status === 422) {
                    this.addValidationError = true
                } else {
                    this.addValidationError = false
                }
                if ('status' in result
                  && result.status === 409
                  && 'detail' in result
                  && typeof result.detail === 'string'
                  && result.detail.includes(overlapingError)
                ) {
                    const overlapErrorPolicyPromiseList = result.detail
                        .replace(overlapingError, '')
                        .replace(']', '')
                        .split(', ')
                        .map((item: string) => this.getInterval(Number(item)))
                    const resultsList = await Promise.all(overlapErrorPolicyPromiseList)
                    this.overlapErrorIntervalList = resultsList.map(item => ({
                        policy: item.policies.map(
                            // @ts-expect-error I trust data from API
                            (policy: number) => this.policyNamesDict[policy],
                        ).join(', '),
                        ...item,
                    }))
                } else {
                    this.overlapErrorIntervalList = []
                }
            } finally {
                this.addLoading = false
            }
            await this.fetchSchedule({ id, name })
        },

        getInterval (id: number) {
            return getSchedule(id)
        },

        async removeInterval (
            { id, policyId, name }: { id: number, policyId: number, name: string },
        ) {
            if (this.deleteLoading) {
                return
            }
            this.deleteLoading = true
            try {
                await deleteSchedule(id)
            } catch (e) {
                console.error(e)
            } finally {
                this.deleteLoading = false
            }
            await this.fetchSchedule({ id: policyId, name })
        },
        clearErrors () {
            this.overlapErrorIntervalList = []
            this.addValidationError = false
        },
    },
})
